<footer class="py-3">
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <p class="text-center">
          © 2025 TIG · Designed by CRPassion Consultores ·
        </p>
      </div>
    </div>
  </div>
</footer>
