import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Trip, User } from '@shared/models';
import { AbstractCRUD } from '../abstract/crud.abstract';
import { catchError, Observable, throwError, timeout } from 'rxjs';
import { CONFIG } from '@config/index';

@Injectable({
  providedIn: 'root',
})
export class UserService extends AbstractCRUD<User> {
  constructor(http: HttpClient) {
    super(http);
  }

  public get resourcePath(): string {
    return '/users';
  }

  public getTripsByDriver(driverId: number, params?: any): Observable<Trip[]> {
    const url = `${this.apiPath}/drivers/${driverId}/trips`;
    return this.http
      .get<Trip[]>(url, {
        params: params,
      })
      .pipe(
        timeout(CONFIG.httpTimeout),
        catchError(ex => throwError(() => ex.error))
      ) as any;
  }
}
